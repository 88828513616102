import { css } from '@emotion/css';

export const styles = {
  container: css`
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    height: 1em;
  `,
  button: css`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #27b4e7;
    border-radius: 50%;
    background-color: transparent;
    border: none;
  `,
};
